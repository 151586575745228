const structureTitle = (offeringType) => {
  if (offeringType === 'course') {
    return 'Course structure';
  }
  if (offeringType === 'major') {
    return 'Major structure';
  }
  if (offeringType === 'minor') {
    return 'Minor structure';
  }
  if (
    offeringType === 'honours-specialisation' ||
    offeringType === 'specialisation'
  ) {
    return 'Specialisation structure';
  }

  return 'Structure';
};

module.exports = {
  structureTitle,
};
